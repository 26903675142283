import { observable, computed, action } from "mobx";
import _ from "lodash";
import uuid from "uuid/v4";
import UserStore from "./UserStore";
import FeatureStore from "./FeatureStore";
import AnnouncementStore from "./AnnouncementStore";
import AppStore from "./AppStore";

class Comment {
	@observable id = null;
	@observable uuid = uuid();
	@observable createdAt = Date.now();
	@observable updatedAt = Date.now();
	@observable deletedAt = null;

	@observable comment = null;
	@observable userId = AppStore.currentUserId;
	@observable commentingOnUuid = null;
	@observable privacy = "public";
	constructor(props) {
		this.updateProps(props);
	}

	@action.bound updateProps(props) {
		_.forIn(props, (v, k) => {
			this[k] = v;
		});
	}
	@computed get serialize() {
		return {
			id: this.id,
			uuid: this.uuid,
			deletedAt: this.deletedAt,
			comment: this.comment,
			userId: this.userId,
			commentingOnUuid: this.commentingOnUuid,
			privacy: this.privacy
		};
	}
	@computed get serialise() {
		return this.serialize;
	}
	@computed get user() {
		if (this.anonymous) return null;
		return UserStore.usersById[this.userId];
	}
	@computed get commentingOn() {
		return (
			FeatureStore.featuresByUuid[this.commentingOnUuid] ||
			AnnouncementStore.featuresByUuid[this.commentingOnUuid]
		);
	}
	@computed get userName() {
		if (this.privacy === "anonymous" && !this.user?.isCurrentUser) return "Anonymous";
		return this.user.fullName + ((this.user.isCurrentUser || AppStore.currentUser.isAdmin) ? ` (${this.privacy})` : "");
	}
}

export default Comment;
