import { observable, computed, action } from "mobx";
import { createTransformer } from "mobx-utils";
import _ from "lodash";
import Subscription from "./Subscription";

class SubscriptionStore {
	@observable subscriptionsByUuid = {};
	@computed get subscriptionsById() {
		return _.mapKeys(this.subscriptionsByUuid, (subscription, uuid) => {
			return subscription.id;
		});
	}
	@computed get subscriptionsByUserId() {
		let subscriptionsByUserId = {};
		this.subscriptions.forEach(re => {
			subscriptionsByUserId[re.userId] ||= [];
			subscriptionsByUserId[re.userId].push(re);
		});
		return subscriptionsByUserId;
	}
	@computed get subscriptionsByTag() {
		let subscriptionsByTag = {};
		this.subscriptions.forEach(su => {
			subscriptionsByTag[su.tag] ||= [];
			subscriptionsByTag[su.tag].push(su);
		});
		return subscriptionsByTag;
	}
	@computed get subscriptions() {
		return Object.values(this.subscriptionsByUuid);
	}
	@action.bound addSubscriptions(subscriptionData) {
		subscriptionData.forEach(co => {
			const newSubscription = new Subscription(co);
			this.subscriptionsByUuid[newSubscription.uuid] = newSubscription;
		});
	}
	@action.bound updateSubscription(subscriptionData) {
		const existingSubscription = this.subscriptionsByUuid[
			subscriptionData.uuid
		];
		if (existingSubscription) {
			existingSubscription.updateProps(subscriptionData);
		} else {
			const newSubscription = new Subscription(subscriptionData);
			this.subscriptionsByUuid[newSubscription.uuid] = newSubscription;
		}
	}

	@action.bound deleteSubscription(subscriptionData) {
		delete this.subscriptionsByUuid[
			subscriptionData.uuid
		];
	}
}

export default new SubscriptionStore();
