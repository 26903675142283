import React, { Component, useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import _ from "lodash";
import TitleBar from "./TitleBar";
import CommentForm from "../layout/CommentForm";
import Comment from "../models/Comment";
import draftToHtml from "draftjs-to-html";
import TextDisplay from "../layout/textEditor/TextDisplay";
import UpdateComment from "../api/UpdateComment";
import CommentStore from "../models/CommentStore";
import AppStore from "../models/AppStore";

const Discussion = observer(({ comments = [], commentingOnUuid }) => {
    const createComment = () => {
        const newComment = new Comment()
        newComment.updateProps({
            commentingOnUuid: commentingOnUuid
        })
        return newComment
    }
	return (
		<div>
			<TitleBar title="Discussion" />
			{comments.length > 0 && (
				<div css={{ padding: "1em" }}>
					{comments.map(c => (
						<CommentDisplay
                            comment={c}
						/>
					))}
				</div>
			)}
			{comments.length > 0 && <TitleBar title="" />}
			<CommentForm
				comment={createComment()}
				css={{ marginTop: "1em" }}
			/>
		</div>
	);
});

const CommentDisplay = observer(({ comment }) => {
	const [isEditing, setIsEditing] = useState(false);
	return (
		<div
			css={{
				fontSize: "0.95em",
				":not(:last-child)": {
					margin: "0 0 1em 0",
					borderBottom: "1px solid #eaeaea"
				}
			}}
		>
			<CommentTitle
				comment={comment}
				isEditing={isEditing}
				setIsEditing={setIsEditing}
			/>
			{!isEditing ? (
				<CommentText>
					<TextDisplay content={comment.comment} />
				</CommentText>
			) : (
				<CommentForm
					comment={comment}
					onSubmit={() => setIsEditing(false)}
					css={{ marginTop: "1em" }}
				/>
			)}
		</div>
	);
});

const CommentTitle = observer(({ comment, isEditing, setIsEditing }) => {
	return (
		<div css={{ display: "flex" }}>
			<div css={{ fontWeight: "bold", marginBottom: "0.25em" }}>
				{comment.userName}
			</div>
			{(AppStore.currentUser.isAdmin || comment.user?.isCurrentUser) && (
				<div css={{ fontSize: "0.9em", color: "#888" }}>
					<div
						css={{
							display: "inline-block",
							marginLeft: "0.5em",
							paddingLeft: "0.5em",
							borderLeft: "1px solid #ccc",
							cursor: "pointer"
						}}
						onClick={() => setIsEditing(!isEditing)}
					>
						Edit
					</div>
					<div
						css={{
							display: "inline-block",
							marginLeft: "0.5em",
							paddingLeft: "0.5em",
							borderLeft: "1px solid #ccc",
							cursor: "pointer"
						}}
						onClick={() => {
							comment.updateProps({
								deletedAt: Math.round(Date.now() / 1000)
							});
							UpdateComment.execute(
								comment,
								comment =>
									CommentStore.deleteComment(
										comment
									)
							);
						}}
					>
						Delete
					</div>
				</div>
			)}
		</div>
	);
});

const CommentText = observer(({ children: text }) => {
	return <div css={{ padding: "0.5em 1em 1em" }}>{text}</div>;
});

export default Discussion