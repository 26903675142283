import React, { useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import UploadFile from "../api/UploadFile";

const FileUploader = observer(() => {
    const [file, setFile] = useState(null)
	return (
		<div>
			<input
				type="file"
				onChange={e => setFile(e.target.files[0])}
			/>
			<button onClick={() => {
                console.log(file)
                const data = new FormData();
                data.append("file", file);
                UploadFile.execute(data);
            }}>
				Upload
			</button>
		</div>
	);
});

export default FileUploader;
