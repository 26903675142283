import { observable, computed, action } from "mobx";
import { createTransformer } from "mobx-utils";
import _ from "lodash";
import Feature from "./Feature";

class FeatureStore {
	@observable featuresByUuid = {};
	@computed get featuresById() {
		return _.mapKeys(this.featuresByUuid, (feature, uuid) => {
			return feature.id;
		});
	}
	@computed get featuresByUserId() {
		let featuresByUserId = {};
		this.features.forEach(fe => {
			featuresByUserId[fe.userId] ||= [];
			featuresByUserId[fe.userId].push(fe);
		});
		return featuresByUserId;
	}
	@computed get featuresByUrl() {
		return _.mapKeys(this.featuresByUuid, (feature, uuid) => {
			return feature.url;
		});
	}
	@computed get features() {
		return _.sortBy(Object.values(this.featuresByUuid), [f => 4 - f.statusVal, 'updatedAt']);
	}
	@action.bound addFeatures(featureData) {
		featureData.forEach(co => {
			const newFeature = new Feature(co);
			this.featuresByUuid[newFeature.uuid] = newFeature;
		});
	}
	@action.bound updateFeature(featureData) {
		const existingFeature = this.featuresByUuid[featureData.uuid];
		if (existingFeature) {
			existingFeature.updateProps(featureData);
		} else {
			const newFeature = new Feature(featureData);
			this.featuresByUuid[newFeature.uuid] = newFeature;
		}
	}

	@action.bound deleteFeature(featureData) {
		delete this.featuresByUuid[featureData.uuid];
	}
}

export default new FeatureStore();
