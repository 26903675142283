import React, { useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import Feature from "../models/Feature";
import Reaction from "../models/Reaction";
import ReactionStore from "../models/ReactionStore";
import UpdateReaction from "../api/UpdateReaction";
import AdminEmoji from "./AdminEmoji";
import AppStore from "../models/AppStore";

const emojiLookUp = {
	"-2": "🤬",
	"-1": "🙁",
	"0": "‍🤔",
	"1": "😀",
	"2": "😍"
};

const emojiDescriptionLookUp = {
	"-2": "I will not use Coincraft with this feature",
	"-1": "I don't like this feature",
	"0": "‍I'm indifferent",
	"1": "I love this feature",
	"2": "I need this feature to use Coincraft"
};

const featureStatusColors = {
	proposed: "hsla(19, 10%, 65%, 1)",
	considering: "hsla(162, 40%, 60%, 1)",
	planned: "hsl(193, 49%, 29%)",
	inProgress: "hsla(42, 91%, 52%, 1)",
	completed: "hsla(12, 90%, 48%, 1)"
};

const FeatureList = observer(({ features = [] }) => {
	return (
		<div css={{ padding: "1em" }}>
			{features.map(f => (
				<FeaturePreview
					feature={f}
					title={f.title}
					status={f.status}
					average={f.averageReactionValue}
					url={f.url}
					featureUuid={f.uuid}
					currentUserReaction={f.currentUserReaction}
				/>
			))}
		</div>
	);
});

const FeaturePreview = observer(({ feature, title, status, average, url, featureUuid, currentUserReaction }) => {
	return (
		<div
			css={{
				background: "white",
				borderRadius: "0.5em",
				border: "1px solid hsla(0, 0%, 92%, 1)",
				padding: "1em",
				":not(:last-child)": { margin: "0 0 1em 0" },
				display: "flex",
				alignItems: "center",
				position: "relative",
				cursor: "pointer",
				boxShadow: "8px 7px 9px -4px #ccc",
				opacity: 0.9,
				"&:hover": {
					opacity: 1,
					boxShadow: "9px 7px 17px -4px #ccc"
				}
			}}
			onClick={() => navigate(`/feature/${url}`)}
		>
			<div css={{ flex: "1 1 auto" }}>
				<FeaturePreviewStatus>{status}</FeaturePreviewStatus>
				<FeaturePreviewTitle>{title}</FeaturePreviewTitle>
			</div>
			{AppStore.currentUser.isAdmin ? (
				<AdminEmoji feature={feature} />
			) : (
				<FeaturePreviewVoter
					css={{ flex: "0 0 auto" }}
					reactingToUuid={featureUuid}
					currentUserReaction={currentUserReaction}
				/>
			)}
		</div>
	);
});

const FeaturePreviewAverage = observer(({ average, ...props }) => {
	return (
		<div css={{ fontSize: "2em", margin: "0 0.5em 0 0" }} {...props}>
			{emojiLookUp[Math.max(Math.round(average), 0)]}
		</div>
	);
});

const FeaturePreviewStatus = observer(({ children: status, ...props }) => {
	return (
		<div
			css={{
				display: "inline-block",
				fontSize: "0.6em",
				letterSpacing: "0.05em",
				fontWeight: "bold",
				margin: "0",
				color: "white",
				backgroundColor: featureStatusColors[status],
				padding: "0.2em 0.8em",
				borderRadius: "0.6em",
				position: "absolute",
				top: "1em",
				left: "1em"
			}}
			{...props}
		>
			{status}
		</div>
	);
});

const FeaturePreviewTitle = observer(({ children: title, ...props }) => {
	return (
		<h3 css={{ fontWeight: "normal", margin: 0, paddingTop: '0.8em' }} {...props}>
			{title}
		</h3>
	);
});

const FeaturePreviewVoter = observer(({reactingToUuid, currentUserReaction, ...props}) => {
    const currentReaction = currentUserReaction || new Reaction({reactingToUuid: reactingToUuid});
	const [hoverVal, setHoverVal] = useState(null)
	const onReact = () => {
		currentReaction.updateProps({value: hoverVal})
		UpdateReaction.execute(currentReaction);
		setHoverVal(null)
	}
	if (currentReaction?.value !== null) {
		return (
			<div
				css={{ fontSize: "2em" }}
				onClick={e => {
					e.stopPropagation();
					onReact();
				}}
				{...props}
			>
				{emojiLookUp[currentReaction.value]}
			</div>
		);
	}
	return (
		<div css={{textAlign: "right"}} {...props}>
			<div css={{ fontSize: "1.25em" }}>
				<VoterEmoji key={"vote-2"} val={-2} setHoverVal={setHoverVal} onClick={e => {
					e.stopPropagation();
					onReact()
				}}>🤬</VoterEmoji>
				<VoterEmoji key={"vote-1"} val={-1} setHoverVal={setHoverVal} onClick={e => {
					e.stopPropagation();
					onReact()
				}}>🙁‍</VoterEmoji>
				<VoterEmoji key={"vote0"} val={0} setHoverVal={setHoverVal} onClick={e => {
					e.stopPropagation();
					onReact()
				}}>🤔</VoterEmoji>
				<VoterEmoji key={"vote+1"} val={1} setHoverVal={setHoverVal} onClick={e => {
					e.stopPropagation();
					onReact()
				}}>😀</VoterEmoji>
				<VoterEmoji key={"vote+2"} val={2} setHoverVal={setHoverVal} onClick={e => {
					e.stopPropagation();
					onReact()
				}}>😍</VoterEmoji>
			</div>
			<div
				css={{
					position: "absolute",
					bottom: "0.5em",
					right: "1em",
					fontSize: "0.75em",
				}}
			>
				{emojiDescriptionLookUp[hoverVal] || ""}
			</div>
		</div>
	);
});

const VoterEmoji = observer(({val, setHoverVal, children: emoji, ...props}) => {
	return (
		<div
			css={{
				display: "inline-block",
				opacity: 0.8,
				"&:hover": {
					fontSize: "1.25em",
					opacity: 1
				}
			}}
			onPointerOver={() => setHoverVal(val)}
			onPointerOut={() => setHoverVal(null)}
			{...props}
		>
			{emoji}
		</div>
	);
})

export default FeatureList