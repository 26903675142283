import React, { Component } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import draftToHtml from "draftjs-to-html";
import TextDisplay from "../layout/textEditor/TextDisplay";

const AnnouncementList = observer(({ announcements }) => {
	return (
		<div css={{ padding: "1em" }}>
			{announcements.map(a => (
				<AnnouncementPreview
					announcement={a}
				/>
			))}
		</div>
	);
});

const AnnouncementPreview = observer(({ announcement }) => {
	return (
		<div
			css={{
				background: "white",
				borderRadius: "0.5em",
				border: "1px solid hsla(0, 0%, 92%, 1)",
				padding: "1em",
				":not(:last-child)": { margin: "0 0 1em 0" },
				cursor: "pointer",
				boxShadow: "8px 7px 9px -4px #ccc",
				opacity: 0.9,
				"&:hover": {
					opacity: 1,
					boxShadow: "9px 7px 17px -4px #ccc"
				}
			}}
			onClick={() => navigate(`/update/${announcement.url}`)}
		>
			<AnnouncementPreviewTitle>
				{announcement.title}
			</AnnouncementPreviewTitle>
			<AnnouncementPreviewDescription>
				<TextDisplay content={announcement.description} />
			</AnnouncementPreviewDescription>
		</div>
	);
});

const AnnouncementPreviewTitle = observer(({ children: title, ...props }) => {
	return <h3 css={{margin: 0}} {...props}>{title}</h3>;
});

const AnnouncementPreviewDescription = observer(
	({ children: description, ...props }) => {
		return (
			<div
				css={{
					marginBottom: "0.5em",
					maxHeight: "9em",
					overflow: "hidden",
					fontSize: "0.9em",
					position: "relative",
					"&:not(.readmore):after": {
						content: `''`,
						position: "absolute",
						left: 0,
						right: 0,
						top: 0,
						height: '9em',
						background:
							"linear-gradient( to bottom,rgba(0,0,0,0),rgba(0,0,0,0) 70%,rgb(255, 255, 255) 94% )",
						zIndex: "1"
					}
				}}
				{...props}
			>
				{description}
			</div>
		);
	}
);

export default AnnouncementList;
