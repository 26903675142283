import React, { useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import axios from "axios";
import _ from "lodash";
import Modal from "./Modal";
import TextEditor from "./textEditor/TextEditor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import FormTextInput from "./FormTextInput";
import Feature from "../models/Feature";
import UpdateFeature from "../api/UpdateFeature";
import AppStore from "../models/AppStore";
import { navigate } from "@reach/router";

const FeatureForm = observer(({ feature }) => {
	feature ||= new Feature();
	const [urlId, setUrlId] = useState(feature.urlId || "");
	const [title, setTitle] = useState(feature.title || "");
	const _descriptionEditor = _.isEmpty(feature.description)
		? EditorState.createEmpty()
		: EditorState.createWithContent(convertFromRaw(feature.description));
	const [descriptionEditor, setDescriptionEditor] = useState(
		_descriptionEditor
	);
	const [status, setStatus] = useState(feature.status || "proposed");
	const [requestingFeeback, setRequestingFeeback] = useState(
		feature.requestingFeeback || false
	);
	const onSubmitFeature = () => {
		feature.updateProps({
			urlId: urlId,
			title: title,
			description: convertToRaw(descriptionEditor.getCurrentContent()),
			status: status,
			requestingFeeback: requestingFeeback
		});
		UpdateFeature.execute(feature, () =>
			navigate(`/feature/${feature.url}`)
		);
	};
	return (
		<Modal
			title={"Request Feature"}
			body={
				<div>
					<div>
						<h4 css={{ marginBottom: "0.25em" }}>Title</h4>
						<FormTextInput
							value={title}
							onChange={e => setTitle(e.target.value)}
						/>
					</div>
					<div>
						<h4 css={{ marginBottom: "0.25em" }}>
							Description
						</h4>
						<TextEditor
							editorState={descriptionEditor}
							onChange={setDescriptionEditor}
							placeholder={placeholderComment}
						/>
					</div>
					{AppStore.currentUser.isAdmin && (
						<div>
							<h4 css={{ marginBottom: "0.25em" }}>Status</h4>
							<select
								value={status}
								onChange={e => setStatus(e.target.value)}
							>
								<option value="proposed">Proposed</option>
								<option value="considering">
									Considering
								</option>
								<option value="planned">Planned</option>
								<option value="inProgress">
									In Progress
								</option>
								<option value="completed">Completed</option>
							</select>
						</div>
					)}
					{AppStore.currentUser.isAdmin && (
						<div>
							<label>
								<input
									type="checkbox"
									checked={requestingFeeback}
									onChange={e =>
										setRequestingFeeback(
											e.target.checked
										)
									}
								/>
								Requesting Feedback?
							</label>
						</div>
					)}
				</div>
			}
			confirmText={"Submit feature"}
			onConfirm={onSubmitFeature}
		/>
	);
});

export default FeatureForm;

const placeholderComment = `We'd love to hear what you'd like to see in Coincraft!
Feature requests and their discussions will remain private between you and Coincraft until it's ready for consideration.`;
