import { observable, computed, action } from "mobx";
import _ from "lodash";
import axios from "axios";
import ServerRequest from "./ServerRequest";
import AppStore from "../models/AppStore";
import SubscriptionStore from "../models/SubscriptionStore";

class UpdateSubscription {
	@observable request = new ServerRequest(
		`${process.env.REACT_APP_API_URL}/api/v2/roadmap/subscription`,
		"post"
	);
	@computed get status() {
		return this.request.status;
	}
	@action.bound execute(subscription) {
        console.log(
			"TCL: UpdateSubscription -> @action.boundexecute -> Subscription",
			subscription,
			subscription.serialize
		);
		this.request.execute(subscription.serialize, data => {
			SubscriptionStore.updateSubscription(data.data.subscription);
			AppStore.setModal(null, {});
		});
	}
}

export default new UpdateSubscription();
