import React, { useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import AnnouncementStore from "../models/AnnouncementStore";
import AnnouncementList from "./AnnouncementList";
import PageHeading from "./PageHeading";
import { Link } from "@reach/router";

const BreadCrumbs = observer(({links}) => {
	return (
		<div
			style={{ fontSize: "0.8em" }}
        >
            {links.map(l => {
				return (
					<span css={{
                        "&:not(:last-child):after": {
                            content: `">"`,
                            padding: "0 0.5em"
                        }
                    }}>
						{l.url ? (
							<Link to={l.url}>{l.label}</Link>
						) : (
							<span>{l.label}</span>
						)}
					</span>
				);
            })}
        </div>
	);
});

export default BreadCrumbs;