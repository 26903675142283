import { observable, computed, action } from "mobx";
import _ from "lodash";
import axios from "axios";
import ServerRequest from "./ServerRequest";
import UserStore from "../models/UserStore";
import AppStore from "../models/AppStore";
import FeatureStore from "../models/FeatureStore";

class UpdateFeature {
	@observable request = new ServerRequest(
		`${process.env.REACT_APP_API_URL}/api/v2/roadmap/feature`,
		"post"
	);
	@computed get status() {
		return this.request.status;
	}
	@action.bound execute(feature, response) {
		this.request.execute(feature.serialize, data => {
			FeatureStore.updateFeature(data.data.feature);
			response && response(data.data.feature);
			AppStore.setModal(null, {});
		});
	}
}

export default new UpdateFeature();
