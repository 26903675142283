import React, { useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import axios from "axios";
import {
	BrowserRouter as Router,
	Route,
	Link,
	Redirect,
	withRouter
} from "react-router-dom";
import _ from "lodash";
import Modal from "./Modal";
import TextEditor from "./textEditor/TextEditor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import FormTextInput from "./FormTextInput";
import Comment from "../models/Comment";
import UpdateComment from "../api/UpdateComment";

const CommentForm = observer(({ comment, onSubmit, ...props }) => {
    comment ||= new Comment();
	const [privacy, setPrivacy] = useState(comment.privacy || false);
	const _commentEditor = _.isEmpty(comment.comment)
		? EditorState.createEmpty()
		: EditorState.createWithContent(
				convertFromRaw(comment.comment)
          );
	const [commentEditor, setCommentEditor] = useState(
		_commentEditor
    );
	const onSubmitComment = () => {
		comment.updateProps({
			comment: convertToRaw(commentEditor.getCurrentContent()),
			privacy: privacy
		});
        UpdateComment.execute(comment);
        const newEditor = EditorState.createEmpty();
		setCommentEditor(newEditor);
		onSubmit && onSubmit();
    };
	return (
		<div {...props}>
			<div>
				<TextEditor
					editorState={commentEditor}
					onChange={setCommentEditor}
					placeholder={placeholderComment}
				/>
			</div>
			<div css={{marginTop: "0.5em", textAlign: "right", fontSize: "0.8em"}}>
				<button
					onClick={onSubmitComment}
					css={{
						flex: "0 0 auto",
						background: "hsl(193, 49%, 29%)",
						fontSize: "1em",
						border: "1px solid hsl(193, 49%, 29%)",
						borderRadius: ".25em 0 0 .25em",
						padding: "0.5em 1em",
						color: "white",
						cursor: "pointer",
						borderRight: "none"
					}}
				>
					Submit
				</button>
				<div css={{
					flex: "0 0 auto",
					position: "relative",
					display: "inline-block",
					"&:after": {
						content: '"▼"',
						padding: '0.9em 1em',
						fontSize: '0.8em',
						position: 'absolute',
						right: '0',
						top: '0',
						zIndex: '1',
						textAlign: 'right',
						width: '100%',
						height: '100%',
						pointerEvents: 'none',
					}
						}}>
					<select
						value={privacy}
						css={{
							background: "white",
							fontSize: "1em",
							border: "solid 1px #ccc",
							borderRadius: "0 .25em .25em 0",
							padding: "0.5em 1em",
							appearance: "none",
							borderLeft: "none",
						}}
						onChange={e => setPrivacy(e.target.value)}
					>
						<option value="public">Publically</option>
						<option value="anonymous">Anonymously</option>
						<option value="private">Privately</option>
					</select>
				</div>
			</div>
		</div>
	);
});

const placeholderComment = 
`Please let us know what you think.
You can submit you comments publically, or privately (only Coincraft will see)
And don't forget to be nice!`

export default CommentForm;
