import React, { useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import axios from "axios";
import {
	BrowserRouter as Router,
	Route,
	Link,
	Redirect,
	withRouter
} from "react-router-dom";
import _ from "lodash";
import Modal from "./Modal";
import TextEditor from "./textEditor/TextEditor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import FormTextInput from "./FormTextInput";
import LoginUser from "../api/LoginUser";

const LoginForm = observer(({ email: _email }) => {
	const [email, setEmail] = useState(_email || "");
	const [password, setPassword] = useState("");
	return (
		<Modal
			title="Login"
			body={
				<div>
					<p css={{marginTop: 0}}>Please login using your Coincraft detiails.</p>
					<FormTextInput
						value={email}
						placeholder="Email Address"
						onChange={e => setEmail(e.target.value)}
						css={{marginBottom: "1em"}}
					/>
					<FormTextInput
						value={password}
						placeholder="Password"
						type="password"
						onChange={e => setPassword(e.target.value)}
					/>
				</div>
			}
			confirmText="Login"
			onConfirm={() => LoginUser.execute(email, password)}
		/>
	);
});

export default LoginForm;
