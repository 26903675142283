import React, { Component } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import _ from "lodash";

const Feed = observer(({ events }) => {
	return (
		<div>
			<h3
				css={{
					borderBottom: "1px solid #ccc",
					padding: "0.25em",
					marginBottom: 0
				}}
			>
				Feed
			</h3>
			{events.map(f => (
				<FeedEvent user={f.user} action={f.action} object={f.object} />
			))}
		</div>
	);
});

const FeedEvent = observer(({ user, action, object }) => {
	return (
		<div
			css={{
				fontSize: "0.8em",
				padding: "0.5em",
				borderBottom: "1px solid #e9e9e9"
			}}
		>
			<b>{user}</b>
			{` ${action} `}
			<b>{object}</b>
		</div>
	);
});

export default Feed;