import { observable, computed, action } from "mobx";
import { createTransformer } from "mobx-utils";
import _ from "lodash";
import User from "./User";
import AppStore from "./AppStore";

class UserStore {
    @observable usersByUuid = {};
	@computed get usersById() {
		return _.mapKeys(this.usersByUuid, (user, uuid) => {
			return user.id;
		});
	}
	@computed get users() {
		return Object.values(this.usersByUuid);
	}
	@action.bound addUsers(userData) {
		userData.forEach(co => {
			if (co.id !== AppStore.currentUserId) {
				const newUser = new User(co);
				this.usersByUuid[newUser.uuid] = newUser;
			}
		});
	}
}

export default new UserStore();
