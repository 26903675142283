import { observable, computed, action } from "mobx";
import { createTransformer } from "mobx-utils";
import _ from "lodash";
import Reaction from "./Reaction";

class ReactionStore {
	@observable reactionsByUuid = {};
	@computed get reactionsById() {
		return _.mapKeys(this.reactionsByUuid, (reaction, uuid) => {
			return reaction.id;
		});
	}
	@computed get reactionsByUserId() {
		let reactionsByUserId = {};
		this.reactions.forEach(re => {
			reactionsByUserId[re.userId] ||= [];
			reactionsByUserId[re.userId].push(re);
		});
		return reactionsByUserId;
	}
	@computed get reactionsByReactingToUuid() {
		let reactionsByReactingToUuid = {};
		this.reactions.forEach(re => {
			reactionsByReactingToUuid[re.reactingToUuid] ||= [];
			reactionsByReactingToUuid[re.reactingToUuid].push(re);
		});
		return reactionsByReactingToUuid;
	}
	@computed get reactions() {
		return Object.values(this.reactionsByUuid);
	}
	@action.bound addReactions(reactionData) {
		reactionData.forEach(co => {
			const newReaction = new Reaction(co);
			this.reactionsByUuid[newReaction.uuid] = newReaction;
		});
	}
	@action.bound updateReaction(reactionData) {
		const existingReaction = this.reactionsByUuid[reactionData.uuid];
		if (existingReaction) {
			existingReaction.updateProps(reactionData);
		} else {
			const newReaction = new Reaction(reactionData);
			this.reactionsByUuid[newReaction.uuid] = newReaction;
		}
	}
}

export default new ReactionStore();
