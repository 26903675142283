import { observable, computed, action } from "mobx";
import _ from "lodash";
import uuid from "uuid/v4";
import FeatureStore from "./FeatureStore";
import CommentStore from "./CommentStore";

class Announcement {
	@observable id = null;
	@observable uuid = uuid();
	@observable createdAt = Date.now();
	@observable updatedAt = Date.now();
	@observable deletedAt = null;

	@observable title = "";
	@observable description = {};
	@observable featureUuid = null;

	constructor(props) {
		this.updateProps(props);
	}

	@action.bound updateProps(props) {
		_.forIn(props, (v, k) => {
			this[k] = v;
		});
	}

	@computed get serialize() {
		return {
			id: this.id,
			uuid: this.uuid,
			deletedAt: this.deletedAt,
			title: this.title,
			description: this.description,
			featureUuid: this.featureUuid
		};
	}
	@computed get serialise() {
		return this.serialize;
	}

	@computed get feature() {
		return FeatureStore.featuresByUuid[this.featureUuid];
	}

	@computed get comments() {
		return CommentStore.commentsByCommentingOnUuid[this.uuid];
	}

	@computed get url() {
		return _.kebabCase(this.title);
	}

	@computed get currentUserReaction() {
		return this.feature.currentUserReaction;
	}

	@computed get currentUserSubscription() {
		return this.feature.currentUserSubscription;
	}
}

export default Announcement;
