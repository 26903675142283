import React, { Component, useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import axios from "axios";

import Editor from "draft-js-plugins-editor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

import createToolbarPlugin, {Separator} from "draft-js-static-toolbar-plugin";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import createImagePlugin from "draft-js-image-plugin";
import createVideoPlugin from "draft-js-video-plugin";
import createEmojiPlugin from "draft-js-emoji-plugin";
import {
	ItalicButton,
	BoldButton,
	UnderlineButton,
	UnorderedListButton,
    OrderedListButton,
} from "draft-js-buttons";

import "./editorStyles.css";
import "./emojiStyles.css";
import "./toolbarStyles.css";
import "./linkStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

const staticToolbarPlugin = createToolbarPlugin();
const linkifyPlugin = createLinkifyPlugin({ target: "_blank" });
const imagePlugin = createImagePlugin();
const videoPlugin = createVideoPlugin();
const emojiPlugin = createEmojiPlugin({
	useNativeArt: true
});
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

const { Toolbar } = staticToolbarPlugin;
const plugins = [
	staticToolbarPlugin,
	linkifyPlugin,
	imagePlugin,
	videoPlugin,
	emojiPlugin
];

const ImageButton = ({onChange, modifier, editorState, ...props}) => {
	let focusTimeout;
    const [src, setSrc] = useState("");
    const [showInput, setShowInput] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
	return (
		<div
			className={"draftJsToolbar__buttonWrapper__1Dmqh"}
			css={{ position: "relative" }}
			onBlur={() => {
				focusTimeout = setTimeout(() => {
					if (isFocused) {
						setShowInput(false);
						setIsFocused(false);
					}
				}, 0);
			}}
			onFocus={() => {
				clearTimeout(focusTimeout);
				if (!isFocused) {
					setIsFocused(true);
				}
			}}
		>
			<button
				onClick={e => setShowInput(!showInput)}
				className={"draftJsToolbar__button__qi1gf"}
			>
				<FontAwesomeIcon
					icon={faImage}
					css={{ fontSize: "1.2em" }}
					fixedWidth
				/>
			</button>
			{showInput && (
				<div
					css={{
						position: "absolute",
						marginTop: "1em",
						top: "100%",
						background: "#fff",
						border: "1px solid #e0e0e0",
						boxShadow: "0 4px 30px 0 gainsboro",
						borderRadius: "0.25em",
						display: "flex",
						padding: "0.25em"
					}}
				>
					<input
						css={{ flex: "1 1 auto", width: "18em" }}
						type="text"
						value={src}
						placeholder="Enter image url here...."
						onChange={e => setSrc(e.target.value)}
					/>
					<button
						css={{ flex: "0 0 auto", padding: "0.25em" }}
						onClick={() => {
							setShowInput(false);
							onChange(modifier(editorState, src));
						}}
					>
						<FontAwesomeIcon
							icon={faPlus}
							css={{ fontSize: "1.2em" }}
							fixedWidth
						/>
					</button>
				</div>
			)}
		</div>
	);
}

const VideoButton = ({ onChange, modifier, editorState, ...props }) => {
	let focusTimeout;
	const [src, setSrc] = useState("");
	const [showInput, setShowInput] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	return (
		<div
			className={"draftJsToolbar__buttonWrapper__1Dmqh"}
			css={{ position: "relative" }}
			onBlur={() => {
				focusTimeout = setTimeout(() => {
					if (isFocused) {
						setShowInput(false);
						setIsFocused(false);
					}
				}, 0);
			}}
			onFocus={() => {
				clearTimeout(focusTimeout);
				if (!isFocused) {
					setIsFocused(true);
				}
			}}
		>
			<button
				onClick={e => setShowInput(!showInput)}
				className={"draftJsToolbar__button__qi1gf"}
			>
				<FontAwesomeIcon
					icon={faYoutube}
					css={{ fontSize: "1.2em" }}
					fixedWidth
				/>
			</button>
			{showInput && (
				<div
					css={{
						position: "absolute",
						marginTop: "1em",
						top: "100%",
						background: "#fff",
						border: "1px solid #e0e0e0",
						boxShadow: "0 4px 30px 0 gainsboro",
						borderRadius: "0.25em",
						display: "flex",
						padding: "0.25em"
					}}
				>
					<input
						css={{ flex: "1 1 auto", width: "18em" }}
						type="text"
						value={src}
						placeholder="Enter video url here...."
						onChange={e => setSrc(e.target.value)}
					/>
					<button
						css={{ flex: "0 0 auto", padding: "0.25em" }}
						onClick={() => {
							setShowInput(false);
							onChange(modifier(editorState, { src: src }));
						}}
					>
						<FontAwesomeIcon
							icon={faPlus}
							css={{ fontSize: "1.2em" }}
							fixedWidth
						/>
					</button>
				</div>
			)}
		</div>
	);
};

const TextEditor = ({ editorState, onChange, readOnly, placeholder, ...props}) => {
	const [placeholderDisplay, setPlaceholderDisplay] = useState(true)
	return (
		<div
			className={"editor" + (readOnly ? " read-only" : "")}
			{...props}
		>
			{readOnly !== true && (
				<Toolbar>
					{// may be use React.Fragment instead of div to improve perfomance after React 16
					externalProps => (
						<>
							<BoldButton {...externalProps} />
							<ItalicButton {...externalProps} />
							<UnderlineButton {...externalProps} />
							<Separator {...externalProps} />
							<UnorderedListButton {...externalProps} />
							<OrderedListButton {...externalProps} />
							<Separator {...externalProps} />
							<ImageButton
								editorState={editorState}
								onChange={e => onChange(e)}
								modifier={imagePlugin.addImage}
							/>
							<VideoButton
								editorState={editorState}
								onChange={e => onChange(e)}
								modifier={videoPlugin.addVideo}
							/>
							<EmojiSelect />
						</>
					)}
				</Toolbar>
			)}
			<Editor
				editorState={editorState}
				onChange={e => onChange(e)}
				readOnly={readOnly}
				plugins={plugins}
				placeholder={placeholderDisplay ? placeholder : null}
				onFocus={() => setPlaceholderDisplay(false)}
				onBlur={() =>
					setPlaceholderDisplay(
						!editorState.getCurrentContent().hasText()
					)
				}
			/>
			<EmojiSuggestions />
		</div>
	);
};

export default TextEditor;