import { observable, computed, action } from "mobx";
import { createTransformer } from "mobx-utils";
import _ from "lodash";
import Announcement from "./Announcement";

class AnnouncementStore {
	@observable announcementsByUuid = {};
	@computed get announcementsById() {
		return _.mapKeys(this.announcementsByUuid, (announcement, uuid) => {
			return announcement.id;
		});
	}
	@computed get announcementsByUrl() {
		return _.mapKeys(this.announcementsByUuid, (announcement, uuid) => {
			return announcement.url;
		});
	}
	@computed get announcementsByFeatureUuid() {
		let announcementsByFeatureUuid = {};
		this.announcements.forEach(an => {
			announcementsByFeatureUuid[an.featureUuid] ||= [];
			announcementsByFeatureUuid[an.featureUuid].push(an);
		});
		return announcementsByFeatureUuid;
	}
	@computed get announcements() {
		return _.sortBy(Object.values(this.announcementsByUuid), ["createdAt"]);
	}
	@action.bound addAnnouncements(announcementData) {
		announcementData.forEach(co => {
			const newAnnouncement = new Announcement(co);
			this.announcementsByUuid[newAnnouncement.uuid] = newAnnouncement;
		});
	}
	@action.bound updateAnnouncement(announcementData) {
		const existingAnnouncement = this.announcementsByUuid[
			announcementData.uuid
		];
		if (existingAnnouncement) {
			existingAnnouncement.updateProps(announcementData);
		} else {
			const newAnnouncement = new Announcement(announcementData);
			this.announcementsByUuid[newAnnouncement.uuid] = newAnnouncement;
		}
	}

	@action.bound deleteAnnouncement(announcementData) {
		delete this.announcementsByUuid[announcementData.uuid];
	}
}

export default new AnnouncementStore();
