import { observable, computed, action, when } from "mobx";
import _ from "lodash";
import axios from "axios";
import ServerRequest from "./ServerRequest";
import AppStore from "../models/AppStore";
import UserStore from "../models/UserStore";
import FeatureStore from "../models/FeatureStore";
import AnnouncementStore from "../models/AnnouncementStore";
import CommentStore from "../models/CommentStore";
import ReactionStore from "../models/ReactionStore";
import SubscriptionStore from "../models/SubscriptionStore";

class FetchRoadmapData {
	@observable request = new ServerRequest(
		`${process.env.REACT_APP_API_URL}/api/v2/roadmap`
	);
	@computed get status() {
		return this.request.status;
	}
	@action.bound execute() {
		this.request.execute({}, data => {
			Object.keys(data.objects).forEach(objName => {
				dataStoreLookup[objName](data.objects[objName]);
			});
		});
	}
}

const dataStoreLookup = {
	features: FeatureStore.addFeatures,
	announcements: AnnouncementStore.addAnnouncements,
	comments: CommentStore.addComments,
	reactions: ReactionStore.addReactions,
	subscriptions: SubscriptionStore.addSubscriptions,
	users: UserStore.addUsers
};

export default new FetchRoadmapData();
