import { observable, computed, action } from "mobx";
import _ from "lodash";
import axios from "axios";

class ServerRequest {
	@observable status = "loading"; // "loading" / "done" / "error"
	@observable url = "";
	@observable requestMethod = "get";
	constructor(url, requestMethod) {
		this.requestMethod = requestMethod || this.requestMethod;
		this.url = url || this.url;
	}
	@action execute(data, response, error) {
		axios(this.url, {
			method: this.requestMethod,
			data: data || {},
			withCredentials: true,
			// headers: {
			// 	"Content-Type": "multipart/form-data"
			// }
		})
			.then(responseData => {
				this.addData(responseData.data);
				if (response) response(responseData.data);
				this.status = "done";
			})
			.catch(errorMessage => {
				console.error(
					"TCL: ServerRequest -> @actionexecute -> errorMessage",
					errorMessage
				);
				this.handleError(errorMessage.data);
				if (error) error(errorMessage.data);
				this.status = "error";
			});
	}
	addData(data) {
		return null;
	}
	handleError(data) {
		return null;
	}
}

export default ServerRequest;