import React, { Component } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarButton = observer(({ children, icon, ...props }) => {
	return (
		<button
			css={{
				background: "white",
				display: "block",
				width: "100%",
				padding: "0.75em 1.5em",
				borderRadius: "0.5em",
				margin: "0.5em 0",
				font: "inherit",
				fontSize: "0.8em",
				border: "1px solid hsla(0,0%,88%,1)",
				textAlign: "left",
				color: "inherit",
				cursor: "pointer",
				boxShadow: "8px 7px 9px -4px #ccc",
				opacity: 0.9,
				"&:hover": {
					opacity: 1,
					boxShadow: "9px 7px 17px -4px #ccc"
				}
			}}
			{...props}
		>
			<FontAwesomeIcon
				icon={icon}
				css={{ marginRight: "1.5em", fontSize: "1.25em" }}
				fixedWidth
			/>
			{children}
		</button>
	);
});

export default SidebarButton;
