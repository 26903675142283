import React, { useRef, useEffect } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import _ from "lodash";
import PageHeading from "./PageHeading";
import draftToHtml from "draftjs-to-html";
import Discussion from "./Discussion";
import AnnouncementStore from "../models/AnnouncementStore";
import TextDisplay from "../layout/textEditor/TextDisplay";
import BreadCrumbs from "./BreadCrumbs";

const AnnouncementPage = observer(({ url, ...props }) => {
	const bodyEl = useRef();
	useEffect(() => {
		bodyEl.current.closest(".bodypanel").scrollTop = 0;
	}, []);
	const announcement = AnnouncementStore.announcementsByUrl[url];
	return (
		<div ref={bodyEl}>
			<BreadCrumbs
				links={[
					{ label: "Home", url: "/" },
					{
						label: announcement.feature.title,
						url: `/feature/${announcement.feature.url}`
					},
					{
						label: announcement.title
					}
				]}
			/>
			<PageHeading
				title={announcement.title}
				collapse={false}
				description={
					<TextDisplay content={announcement.description} />
				}
			/>
			<Discussion
				comments={announcement.comments}
				commentingOnUuid={announcement.uuid}
			/>
		</div>
	);
});

export default AnnouncementPage;
