import { observable, computed, action } from "mobx";
import { createTransformer } from "mobx-utils";
import _ from "lodash";
import Comment from "./Comment";

class CommentStore {
	@observable commentsByUuid = {};
	@computed get commentsById() {
		return _.mapKeys(this.commentsByUuid, (comment, uuid) => {
			return comment.id;
		});
	}
	@computed get commentsByUserId() {
		let commentsByUserId = {};
		this.comments.forEach(co => {
			commentsByUserId[co.userId] ||= [];
			commentsByUserId[co.userId].push(co);
		});
		return commentsByUserId;
	}
	@computed get commentsByCommentingOnUuid() {
		let commentsByCommentingOnUuid = {};
		this.comments.forEach(co => {
			commentsByCommentingOnUuid[co.commentingOnUuid] ||= [];
			commentsByCommentingOnUuid[co.commentingOnUuid].push(co);
		});
		return commentsByCommentingOnUuid;
	}
	@computed get comments() {
		return Object.values(this.commentsByUuid);
	}
	@action.bound addComments(commentData) {
		commentData.forEach(co => {
			const newComment = new Comment(co);
			this.commentsByUuid[newComment.uuid] = newComment;
		});
	}
	@action.bound updateComment(commentData) {
		const existingComment = this.commentsByUuid[commentData.uuid];
		if (existingComment) {
			existingComment.updateProps(commentData);
		} else {
			const newComment = new Comment(commentData);
			this.commentsByUuid[newComment.uuid] = newComment;
		}
	}

	@action.bound deleteComment(commentData) {
		delete this.commentsByUuid[commentData.uuid];
	}
}

export default new CommentStore();
