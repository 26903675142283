import React, { Component } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import _ from "lodash";
import SidebarButton from "./SidebarButton";
import { faBullhorn, faEye, faEdit, faTrashAlt, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AppStore from "../../models/AppStore";
import Announcement from "../../models/Announcement";
import AnnouncementStore from "../../models/AnnouncementStore";
import UpdateAnnouncement from "../../api/UpdateAnnouncement";
import UpdateSubscription from "../../api/UpdateSubscription";
import DeleteSubscription from "../../api/DeleteSubscription";
import Subscription from "../../models/Subscription";

const AnnouncementSidebar = observer(({ url }) => {
	const announcement = AnnouncementStore.announcementsByUrl[url];
	console.log(AppStore.currentUser);
	return (
		<div>
			{AppStore.currentUser.isAdmin && (
				<>
					<SidebarButton
						icon={faEdit}
						onClick={() => {
							AppStore.setModal("announcementForm", {
								announcement: announcement
							});
						}}
					>
						Edit
					</SidebarButton>
					<SidebarButton
						icon={faTrashAlt}
						onClick={() => {
							announcement.updateProps({
								deletedAt: Math.round(Date.now() / 1000)
							});
							UpdateAnnouncement.execute(announcement);
						}}
					>
						Delete
					</SidebarButton>
				</>
			)}
			{announcement.feature.currentUserSubscription ? (
				<SidebarButton
					icon={faEyeSlash}
					onClick={() =>
						unsubscribe(announcement.feature.currentUserSubscription)
					}
				>
					Unsubscribe
				</SidebarButton>
			) : (
				<SidebarButton
					icon={faEye}
					onClick={() => {
						const sub = new Subscription({ tag: announcement.feature.uuid });
						subscribe(sub);
					}}
				>
					Subscribe
				</SidebarButton>
			)}
		</div>
	);
});

const subscribe = sub => {
	UpdateSubscription.execute(sub);
};

const unsubscribe = sub => {
	DeleteSubscription.execute(sub);
};

export default AnnouncementSidebar
