import React, { Component } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import FeatureForm from "../layout/FeatureForm";
import LoginForm from "../layout/LoginForm";
import AnnouncementForm from "../layout/AnnouncementForm";

export default {
	loginForm: props => <LoginForm {...props} />,
	featureForm: props => <FeatureForm {...props} />,
	announcementForm: props => <AnnouncementForm {...props} />
};