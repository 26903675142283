import { observable, computed, action } from "mobx";
import _ from "lodash";
import axios from "axios";
import ServerRequest from "./ServerRequest";
import UserStore from "../models/UserStore";
import AppStore from "../models/AppStore";

class LoginUser {
	@observable request = new ServerRequest(
		`${process.env.REACT_APP_API_URL}/api/v2/user/login`,
		"post"
	);
	@computed get status() {
		return this.request.status;
	}
	@action.bound execute(email, password) {
		this.request.execute(
			{ email: email, password: password },
			data => {
				UserStore.addUsers([data.data.user]);
				AppStore.setCurrentUserId(data.data.user.id);
			}
		);
	}
}

export default new LoginUser();
