import React, { Component } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import { Link } from "@reach/router";

const TitleBar = observer(({ title, linkLabel, link }) => {
	return (
		<div
			css={{
				borderBottom: "1px solid #ccc",
				padding: "1em 0.25em 0.25em 0.25em",
				marginBottom: 0,
				display: "flex",
				alignItems: "flex-end"
			}}
		>
			<h3 css={{ margin: 0, flex: "1 1 auto" }}>{title}</h3>
			{link && linkLabel && (
				<Link css={{ flex: "0 0 auto" }} to={link}>
					{linkLabel}
				</Link>
			)}
		</div>
	);
});

export default TitleBar;
