import React, { Component } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import _ from "lodash";
import SidebarButton from "./SidebarButton";
import { faBullhorn, faEye, faEyeSlash, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AppStore from "../../models/AppStore";
import Announcement from "../../models/Announcement";
import FeatureStore from "../../models/FeatureStore";
import UpdateFeature from "../../api/UpdateFeature";
import Subscription from "../../models/Subscription";
import UpdateSubscription from "../../api/UpdateSubscription";
import DeleteSubscription from "../../api/DeleteSubscription";

const FeatureSidebar = observer(({ url }) => {
	const feature = FeatureStore.featuresByUrl[url];
	return (
		<div>
			{AppStore.currentUser.isAdmin && (
				<SidebarButton
					icon={faBullhorn}
					onClick={() => {
						const newAnnouncement = new Announcement();
						newAnnouncement.updateProps({
							featureUuid: feature.uuid
						});
						AppStore.setModal("announcementForm", {
							announcement: newAnnouncement
						});
					}}
				>
					Announce Update
				</SidebarButton>
			)}
			{(AppStore.currentUser.isAdmin ||
				feature.createdById === AppStore.currentUserId) && (
				<>
					<SidebarButton
						icon={faEdit}
						onClick={() => {
							AppStore.setModal("featureForm", {
								feature: feature
							});
						}}
					>
						Edit
					</SidebarButton>
					<SidebarButton
						icon={faTrashAlt}
						onClick={() => {
							feature.updateProps({
								deletedAt: Math.round(Date.now() / 1000)
							});
							UpdateFeature.execute(feature);
						}}
					>
						Delete
					</SidebarButton>
				</>
			)}
			{feature.currentUserSubscription ? (
				<SidebarButton
					icon={faEyeSlash}
					onClick={() =>
						unsubscribe(feature.currentUserSubscription)
					}
				>
					Unsubscribe
				</SidebarButton>
			) : (
				<SidebarButton
					icon={faEye}
					onClick={() => {
						const sub = new Subscription({ tag: feature.uuid });
						subscribe(sub);
					}}
				>
					Subscribe
				</SidebarButton>
			)}
		</div>
	);
});

const subscribe = sub => {
	UpdateSubscription.execute(sub)
}

const unsubscribe = sub => {
	DeleteSubscription.execute(sub);
};

export default FeatureSidebar
