import React, { Component } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import axios from "axios";
import {
	BrowserRouter as Router,
	Route,
	Link,
	Redirect,
	withRouter
} from "react-router-dom";
import _ from "lodash";
import AppStore from "../models/AppStore";

const Modal = observer(({ title, body, confirmText, onConfirm }) => {
	return (
		<div
			css={{
				position: "absolute",
				top: 0,
				right: 0,
				left: 0,
				bottom: 0,
				display: "flex",
				alignItems: "flex-start",
				justifyContent: "center"
			}}
		>
			<ModalBg />
			<div
				css={{
					background: "white",
					display: "flex",
					flexDirection: "column",
					zIndex: 100,
					width: "90%",
					maxWidth: "50em",
					maxHeight: "75%",
					borderRadius: "0.5em",
					marginTop: "3em"
				}}
			>
				<ModalHeader css={{ flex: "0 0 auto" }}>{title}</ModalHeader>
				<ModalBody css={{ flex: "1 1 auto", overflowY: "auto" }}>
					{body}
				</ModalBody>
				<ModalFooter
					css={{ flex: "0 0 auto" }}
					confirmText={confirmText}
					onConfirm={onConfirm}
				/>
			</div>
		</div>
	);
});

const ModalBg = observer(({ children, ...props }) => {
	return (
		<div
			css={{
				background: "hsla(0, 0%, 0%, 0.65)",
				zIndex: 80,
				position: "absolute",
				top: 0,
				right: 0,
				left: 0,
				bottom: 0
			}}
			onClick={e => {
				e.stopPropagation();
				AppStore.setModal(null, {});
			}}
			{...props}
		/>
	);
});

const ModalHeader = observer(({ children: title, ...props }) => {
	return (
		<div
			css={{ padding: "1em", borderBottom: "1px solid #ddd" }}
			{...props}
		>
			<h3 css={{ margin: 0 }}>{title}</h3>
		</div>
	);
});

const ModalBody = observer(({ children, ...props }) => {
	return (
		<div css={{ padding: "1em 2em" }} {...props}>
			{children}
		</div>
	);
});

const ModalFooter = observer(({ onConfirm, confirmText, ...props }) => {
	return (
		<div
			css={{
				padding: "1em",
				borderTop: "1px solid #ddd",
				display: "flex",
				justifyContent: "flex-end",
				alignItems: "center"
			}}
			{...props}
		>
			<div
				css={{ flex: "0 0 auto", cursor: "pointer" }}
				onClick={() => AppStore.setModal(null, {})}
			>
				Cancel
			</div>
			<button
				onClick={onConfirm}
				css={{
					flex: "0 0 auto",
					marginLeft: "1em",
					background: "hsl(193, 49%, 29%)",
					fontSize: "1em",
					border: "none",
					borderRadius: "0.25em",
					padding: "0.5em 1em",
					color: "white",
					cursor: "pointer"
				}}
			>
				{confirmText}
			</button>
		</div>
	);
});

export default Modal;
