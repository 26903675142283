import { observable, computed, action } from "mobx";
import _ from "lodash";
import axios from "axios";
import ServerRequest from "./ServerRequest";

class UploadFile {
	@observable request = new ServerRequest(
		`${process.env.REACT_APP_API_URL}/api/v2/files/upload`,
		"post"
	);
	@computed get status() {
		return this.request.status;
	}
	@action.bound execute(data) {
		this.request.execute(
			data,
			data => {
				console.log(data)
			}
		);
	}
}

export default new UploadFile();
