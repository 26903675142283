import React, { useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import UpdateReaction from "../api/UpdateReaction";
import Reaction from "../models/Reaction";

const featureStatusColors = {
	proposed: "hsla(19, 10%, 65%, 1)",
	considering: "hsla(162, 40%, 60%, 1)",
	planned: "hsl(193, 49%, 29%)",
	inProgress: "hsla(42, 91%, 52%, 1)",
	completed: "hsla(12, 90%, 48%, 1)"
};

const FeatureStatus = observer(({ children: status, ...props }) => {
	return (
		<div
			css={{
				display: "inline-block",
				fontSize: "0.8em",
				letterSpacing: "0.05em",
				fontWeight: "bold",
				margin: "0",
				color: "white",
				backgroundColor: featureStatusColors[status],
				padding: "0.2em 1em",
				borderRadius: "0.6em",
				position: "absolute",
				top: "1em",
				right: "0"
			}}
			{...props}
		>
			{status}
		</div>
	);
});

export default FeatureStatus;


