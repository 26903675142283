import { observable, computed, action } from "mobx";
import _ from "lodash";
import axios from "axios";
import ServerRequest from "./ServerRequest";
import UserStore from "../models/UserStore";
import AppStore from "../models/AppStore";
import FeatureStore from "../models/FeatureStore";
import ReactionStore from "../models/ReactionStore";

class UpdateReaction {
	@observable request = new ServerRequest(
		`${process.env.REACT_APP_API_URL}/api/v2/roadmap/reaction`,
		"post"
	);
	@computed get status() {
		return this.request.status;
	}
	@action.bound execute(reaction) {
		this.request.execute(reaction.serialize, data => {
			ReactionStore.updateReaction(data.data.reaction);
			AppStore.setModal(null, {});
		});
	}
}

export default new UpdateReaction();
