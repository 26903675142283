import React, { useState, useRef, useEffect } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import { Router, Route, Link, Redirect, withRouter } from "react-router-dom";

const PageHeading = observer(({ title, description, collapse=true }) => {
	return (
		<>
			{/* <BreadCrumbs /> */}
			<PageTitle>{title}</PageTitle>
			<PageDescription collapse={collapse}>{description}</PageDescription>
		</>
	);
});

const PageTitle = observer(({ children: title }) => {
	return <h1 css={{marginBottom: 0}}>{title}</h1>;
});

const PageDescription = observer(({ collapse, children: description }) => {
	const [readMore, setReadMore] = useState(false)
	const [isOverflowing, setIsOverflowing] = useState(false);
	// let isOverflowing;
	const descriptionOuter = useRef();
	const descriptionInner = useRef();
	useEffect(
		() =>{
			setIsOverflowing(
				descriptionOuter.current?.clientHeight <
					descriptionInner.current?.clientHeight
			);
		}, [description])
	;
	return (
		<div>
			<div
				className={!isOverflowing || readMore ? `readmore` : ""}
				ref={descriptionOuter}
				css={{
					maxHeight: (!collapse || readMore) ? "none" : "9em",
					overflow: "hidden",
					margin: "1em 0",
					position: "relative",
					"&:not(.readmore):after": {
						content: `''`,
						position: "absolute",
						left: 0,
						right: 0,
						top: 0,
						height: "9em",
						background:
							"linear-gradient( to bottom,rgba(0,0,0,0),rgba(0,0,0,0) 70%,rgb(250, 250, 250) 94% )",
						zIndex: "1"
					}
				}}
			>
				<div ref={descriptionInner}>{description}</div>
			</div>
			{isOverflowing && (
				<div
					style={{
						color: "hsla(0, 0%, 35%, 1)",
						textDecoration: "underline",
						margin: "0 0 1em 1em"
					}}
					onClick={() => setReadMore(!readMore)}
				>
					{readMore ? "Read less..." : "Read more..."}
				</div>
			)}
		</div>
	);
});

export default PageHeading;
