import { observable, computed, action } from "mobx";
import _ from "lodash";
import axios from "axios";
import ServerRequest from "./ServerRequest";
import AppStore from "../models/AppStore";
import UserStore from "../models/UserStore";

class FetchUser {
	@observable request = new ServerRequest(
		`${process.env.REACT_APP_API_URL}/api/v2/user/current_user`
	);
	@computed get status() {
		return this.request.status;
	}
	@action.bound execute() {
		this.request.execute({}, data => {
			UserStore.addUsers([data.data.user]);
			AppStore.setCurrentUserId(data.data.user.id);
		});
	}
}

export default new FetchUser();
