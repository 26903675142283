import React, { useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import axios from "axios";
import {
	BrowserRouter as Router,
	Route,
	Link,
	Redirect,
	withRouter
} from "react-router-dom";
import _ from "lodash";
import Modal from "./Modal";
import TextEditor from "./textEditor/TextEditor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

const FormTextInput = observer(({ value, onChange, type, ...props }) => {
	return (
		<input
			type={type || "text"}
			value={value}
			onChange={onChange}
			css={{
				width: "calc(100% - 1em)",
				padding: "0.5em",
				borderRadius: "0.4em",
				border: "1px solid #ccc",
				fontSize: "inherit",
				fontFamily: "inherit",
				color: "inherit"
			}}
			{...props}
		/>
	);
});

export default FormTextInput;
