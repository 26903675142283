import React, { Component } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import _ from "lodash";
import SidebarButton from "./SidebarButton";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const GeneralSidebar = observer(() => {
	return (
		<div></div>
	);
});

export default GeneralSidebar;
