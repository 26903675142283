import { observable, computed, action, when } from "mobx";
import { createTransformer } from "mobx-utils";
import _ from "lodash";
import FetchUser from "../api/FetchUser";
import LoginUser from "../api/LoginUser";
import FetchRoadmapData from "../api/FetchRoadmapData";
import UserStore from "./UserStore";

class AppStore {
	@observable currentUserId = null;
    @observable modal = null;
    @observable modalData = {};
	constructor() {
		FetchUser.execute();
		when(() => this.currentUserId, () => FetchRoadmapData.execute());
	}
	@computed get status() {
		return this.currentUserId ? "ready" : "loading";
	}
	@computed get needsLogin() {
		return (
			!this.currentUserId && ["done", "error"].includes(FetchUser.status)
		);
	}
	@computed get currentUser() {
		return UserStore.usersById[this.currentUserId]
	}
	@action.bound setCurrentUserId(userId) {
		this.currentUserId = userId;
	}
	@action.bound setModal(modal, modalData) {
        this.modal = modal;
        this.modalData = modalData;
	}
}

export default new AppStore();
