import React, { useState, useRef, useEffect } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import FeatureStore from "../models/FeatureStore";
import FeatureList from "./FeatureList";
import PageHeading from "./PageHeading";
import BreadCrumbs from "./BreadCrumbs";

const FeaturesPage = observer(() => {
    const bodyEl = useRef();
	useEffect(() => {
		bodyEl.current.closest(".bodypanel").scrollTop = 0;
	}, []);
	return (
		<div ref={bodyEl}>
			<BreadCrumbs
				links={[
					{ label: "Home", url: "/" },
					{
						label: "Features"
					}
				]}
			/>
			<PageHeading title={"Features"} />
			<FeatureList features={FeatureStore.features} />
		</div>
	);
});

export default FeaturesPage;
