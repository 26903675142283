import React, { useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import UpdateReaction from "../api/UpdateReaction";
import Reaction from "../models/Reaction";

const emojiLookUp = {
	"-2": "🤬",
	"-1": "🙁",
	"0": "‍🤔",
	"1": "😀",
	"2": "😍"
};

const emojiDescriptionLookUp = {
	"-2": "I will not use Coincraft with this feature",
	"-1": "I don't like this feature",
	"0": "‍I'm indifferent",
	"1": "I love this feature",
	"2": "I need this feature to use Coincraft"
};

const featureStatusColors = {
	proposed: "hsla(19, 10%, 65%, 1)",
	considering: "hsla(162, 40%, 60%, 1)",
	planned: "hsl(193, 49%, 29%)",
	inProgress: "hsla(42, 91%, 52%, 1)",
	completed: "hsla(12, 90%, 48%, 1)"
};

const ReactionBody = observer(
	({ reactingToUuid, currentUserReaction, ...props }) => {
		const currentReaction =
			currentUserReaction ||
			new Reaction({ reactingToUuid: reactingToUuid });
		const [hoverVal, setHoverVal] = useState(null);
		const onReact = () => {
			currentReaction.updateProps({ value: hoverVal });
			UpdateReaction.execute(currentReaction);
			setHoverVal(null);
		};
		return (
			<div
				css={{
					textAlign: "center",
					fontSize: "1.5em",
					padding: "1em 0 0 0",
					borderTop: "1px solid #ccc",
					position: "relative"
				}}
				{...props}
			>
				<div css={{ fontSize: "1.25em" }}>
					<VoterEmoji
						key={"vote-2"}
						val={-2}
						selected={
							!hoverVal && currentReaction.value === -2
						}
						setHoverVal={setHoverVal}
						onClick={e => {
							if (currentReaction.value === -2)
								setHoverVal(null);
							e.stopPropagation();
							onReact();
						}}
					>
						🤬
					</VoterEmoji>
					<VoterEmoji
						key={"vote-1"}
						val={-1}
						selected={
							!hoverVal && currentReaction.value === -1
						}
						setHoverVal={setHoverVal}
						onClick={e => {
							if (currentReaction.value === -1)
								setHoverVal(null);
							e.stopPropagation();
							onReact();
						}}
					>
						🙁‍
					</VoterEmoji>
					<VoterEmoji
						key={"vote0"}
						val={0}
						selected={
							!hoverVal && currentReaction.value === 0
						}
						setHoverVal={setHoverVal}
						onClick={e => {
							if (currentReaction.value === 0)
								setHoverVal(null);
							e.stopPropagation();
							onReact();
						}}
					>
						🤔
					</VoterEmoji>
					<VoterEmoji
						key={"vote+1"}
						val={1}
						selected={
							!hoverVal && currentReaction.value === 1
						}
						setHoverVal={setHoverVal}
						onClick={e => {
							if (currentReaction.value === 1)
								setHoverVal(null);
							e.stopPropagation();
							onReact();
						}}
					>
						😀
					</VoterEmoji>
					<VoterEmoji
						key={"vote+2"}
						val={2}
						selected={
							!hoverVal && currentReaction.value === 2
						}
						setHoverVal={setHoverVal}
						onClick={e => {
							if (currentReaction.value === 2)
								setHoverVal(null);
							e.stopPropagation();
							onReact();
						}}
					>
						😍
					</VoterEmoji>
				</div>
				<div
					css={{
						position: "absolute",
						width: "100%",
						fontSize: "0.6em"
					}}
				>
					{emojiDescriptionLookUp[
						hoverVal || currentReaction.value
					] || ""}
				</div>
			</div>
		);
	}
);

const VoterEmoji = observer(
	({ val, setHoverVal, selected, children: emoji, ...props }) => {
		return (
			<div
				css={{
					display: "inline-block",
					opacity: selected ? 1 : 0.6,
					fontSize: selected ? "1.25em" : "1em",
					"&:hover": {
						fontSize: "1.25em",
						opacity: 1
                    },
                    cursor: "pointer"
				}}
				onPointerOver={() => setHoverVal(val)}
				onPointerOut={() => setHoverVal(null)}
				{...props}
			>
				{emoji}
			</div>
		);
	}
);

export default ReactionBody;


