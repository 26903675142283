import React, { useRef, useEffect } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import _ from "lodash";
import FeatureStore from "../models/FeatureStore";
import PageHeading from "./PageHeading";
import draftToHtml from "draftjs-to-html";
import Discussion from "./Discussion";
import AnnouncementList from "./AnnouncementList";
import TitleBar from "./TitleBar";
import TextDisplay from "../layout/textEditor/TextDisplay";
import BreadCrumbs from "./BreadCrumbs";
import ReactionBody from "./ReactionBody";
import FeatureStatus from "./FeatureStatus";
import AppStore from "../models/AppStore";
import AdminEmoji from "./AdminEmoji";


const FeaturePage = observer(({ url, ...props }) => {
    const bodyEl = useRef();
	useEffect(() => {
		bodyEl.current.closest(".bodypanel").scrollTop = 0;
	}, []);
	const feature = FeatureStore.featuresByUrl[url];
	return (
		<div css={{ position: "relative" }} ref={bodyEl}>
			<BreadCrumbs
				links={[
					{ label: "Home", url: "/" },
					{ label: feature.title }
				]}
			/>
			<FeatureStatus>{feature.status}</FeatureStatus>
			<PageHeading
				title={feature.title}
				description={<TextDisplay content={feature.description} />}
			/>
			{AppStore.currentUser.isAdmin && <div>{feature.createdBy?.fullName}</div>}
			{AppStore.currentUser.isAdmin ? (
				<AdminEmoji feature={feature} css={{ fontSize: "1.5em" }} />
			) : (
				<ReactionBody
					reactingToUuid={feature.uuid}
					currentUserReaction={feature.currentUserReaction}
				/>
			)}
			{feature.announcements.length > 0 && (
				<div>
					<TitleBar title="Updates" />
					<AnnouncementList
						announcements={feature.announcements}
					/>
				</div>
			)}
			<Discussion
				comments={feature.comments}
				commentingOnUuid={feature.uuid}
			/>
		</div>
	);
});

export default FeaturePage