import React, { useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import axios from "axios";
import {
	BrowserRouter as Router,
	Route,
	Link,
	Redirect,
	withRouter
} from "react-router-dom";
import _ from "lodash";
import Modal from "./Modal";
import TextEditor from "./textEditor/TextEditor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import FormTextInput from "./FormTextInput";
import Announcement from "../models/Announcement";
import UpdateAnnouncement from "../api/UpdateAnnouncement";
import { navigate } from "@reach/router";

const AnnouncementForm = observer(({ announcement }) => {
	announcement ||= new Announcement();
	const [title, setTitle] = useState(announcement.title || "");
	const _descriptionEditor = _.isEmpty(announcement.description)
		? EditorState.createEmpty()
		: EditorState.createWithContent(convertFromRaw(announcement.description));
	const [descriptionEditor, setDescriptionEditor] = useState(
		_descriptionEditor
	);
	const onSubmitAnnouncement = () => {
		announcement.updateProps({
			title: title,
			description: convertToRaw(descriptionEditor.getCurrentContent())
		});
		UpdateAnnouncement.execute(announcement, () =>
			navigate(`/update/${announcement.url}`)
		);
	};
	return (
		<Modal
			title={"Request Announcement"}
			body={
				<div>
					<div>
						<h4 css={{ marginBottom: "0.25em" }}>Title</h4>
						<FormTextInput
							value={title}
							onChange={e => setTitle(e.target.value)}
						/>
					</div>
					<div>
						<h4 css={{ marginBottom: "0.25em" }}>Description</h4>
						<TextEditor
							editorState={descriptionEditor}
							onChange={setDescriptionEditor}
						/>
					</div>
				</div>
			}
			confirmText={"Submit announcement"}
			onConfirm={onSubmitAnnouncement}
		/>
	);
});

export default AnnouncementForm;
