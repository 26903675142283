import { observable, computed, action } from "mobx";
import _ from "lodash";
import axios from "axios";
import ServerRequest from "./ServerRequest";
import AppStore from "../models/AppStore";
import SubscriptionStore from "../models/SubscriptionStore";

class DeleteSubscription {
	@observable request = new ServerRequest(
		`${process.env.REACT_APP_API_URL}/api/v2/roadmap/subscription`,
		"delete"
	);
	@computed get status() {
		return this.request.status;
	}
	@action.bound execute(Subscription) {
		this.request.execute(Subscription.serialize, data => {
			SubscriptionStore.deleteSubscription(data.data.subscription);
			AppStore.setModal(null, {});
		});
	}
}

export default new DeleteSubscription();
