import { observable, computed, action } from "mobx";
import _ from "lodash";
import uuid from "uuid/v4";
import FeatureStore from "./FeatureStore";
import CommentStore from "./CommentStore";
import ReactionStore from "./ReactionStore";
import AppStore from "./AppStore";
import SubscriptionStore from "./SubscriptionStore";

class User {
	@observable id = null;
	@observable uuid = uuid();

	@observable firstName = "";
	@observable lastName = "";

	@observable isAdmin = false;

	constructor(props) {
		_.forIn(props, (v, k) => (this[k] = v));
	}

	@computed get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}

	@computed get isCurrentUser() {
		return this.id === AppStore.currentUserId;
	}

	@computed get features() {
		return FeatureStore.featuresByUserId[this.id] || [];
	}

	@computed get comments() {
		return CommentStore.commentsByUserId[this.id] || [];
	}

	@computed get reactions() {
		return ReactionStore.reactionsByUserId[this.id] || [];
	}

	@computed get subscriptions() {
		return SubscriptionStore.subscriptionsByUserId[this.id] || [];
	}

	@computed get reactionsByReactingToUuid() {
		let reactionsByReactingToUuid = {};
		this.reactions.forEach(re => {
			reactionsByReactingToUuid[re.reactingToUuid] = re;
		});
		return reactionsByReactingToUuid;
	}

	@computed get subscriptionsByTag() {
		let subscriptionsByTag = {};
		this.subscriptions.forEach(su => {
			subscriptionsByTag[su.tag] = su;
		});
		return subscriptionsByTag;
	}
}

export default User;
