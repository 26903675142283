import { observable, computed, action } from "mobx";
import _ from "lodash";
import axios from "axios";
import ServerRequest from "./ServerRequest";
import UserStore from "../models/UserStore";
import AppStore from "../models/AppStore";
import FeatureStore from "../models/FeatureStore";
import AnnouncementStore from "../models/AnnouncementStore";

class UpdateAnouncement {
	@observable request = new ServerRequest(
		`${process.env.REACT_APP_API_URL}/api/v2/roadmap/announcement`,
		"post"
	);
	@computed get status() {
		return this.request.status;
	}
	@action.bound execute(announcement, response) {
		this.request.execute(announcement.serialize, data => {
			AnnouncementStore.updateAnnouncement(data.data.announcement);
			response && response(data.data.announcement);
			AppStore.setModal(null, {});
		});
	}
}

export default new UpdateAnouncement();
