import React, { useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import UpdateReaction from "../api/UpdateReaction";
import Reaction from "../models/Reaction";

const emojiLookUp = {
	"-2": "🤬",
	"-1": "🙁",
	"0": "‍🤔",
	"1": "😀",
	"2": "😍"
};

const AdminEmoji = observer(
	({ feature, ...props }) => {
		return (
			<div
				css={{
					textAlign: "center",
					position: "relative"
				}}
				{...props}
			>
				<div>
					<EmojiCount
						key={"vote-2"}
						val={-2}
						count={feature.reactionsByValue[-2]}
					>
						🤬
					</EmojiCount>
					<EmojiCount
						key={"vote-1"}
						val={-1}
						count={feature.reactionsByValue[-1]}
					>
						🙁‍
					</EmojiCount>
					<EmojiCount
						key={"vote0"}
						val={0}
						count={feature.reactionsByValue[0]}
					>
						🤔
					</EmojiCount>
					<EmojiCount
						key={"vote+1"}
						val={1}
						count={feature.reactionsByValue[1]}
					>
						😀
					</EmojiCount>
					<EmojiCount
						key={"vote+2"}
						val={2}
						count={feature.reactionsByValue[2]}
					>
						😍
					</EmojiCount>
				</div>
			</div>
		);
	}
);

const EmojiCount = observer(
	({ count, children: emoji, ...props }) => {
		return (
			<div
				css={{
					display: "inline-block",
					textAlign: "center"
				}}
				{...props}
			>
				<div>{count}</div>
				<div>{emoji}</div>
			</div>
		);
	}
);

export default AdminEmoji;


