import React, { useState } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import _ from "lodash";
import TextEditor from "./TextEditor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

const TextDisplay = observer(({ content, ...props }) => {
	const editorContent = EditorState.createWithContent(
		convertFromRaw(content)
	);
    return <TextEditor 
        editorState={editorContent} 
        readOnly={true} 
        onChange={() => null}
    />;
});

export default TextDisplay;
