import { observable, computed, action } from "mobx";
import _ from "lodash";
import uuid from "uuid/v4";
import UserStore from "./UserStore";
import FeatureStore from "./FeatureStore";
import AppStore from "./AppStore";

class Subscription {
	@observable id = null;
	@observable uuid = uuid();

	@observable tag = null;
	@observable userId = AppStore.currentUserId;

	constructor(props) {
		this.updateProps(props);
	}

	@action.bound updateProps(props) {
		_.forIn(props, (v, k) => {
			this[k] = v;
		});
	}

	@computed get serialize() {
		return {
			id: this.id,
			uuid: this.uuid,
			userId: this.userId,
			tag: this.tag,
		};
	}
	@computed get serialise() {
		return this.serialize;
	}

	@computed get user() {
		return UserStore.usersById[this.userId];
	}

	@computed get subscribedTo() {
		return FeatureStore.featuresByUuid[this.tag];
	}
}

export default Subscription;
