import React, {Component, useState} from 'react';
/** @jsx jsx */ import { jsx } from '@emotion/core';
import { observer } from "mobx-react";
import './App.css';
import axios from 'axios'
import { Router, Link, navigate } from "@reach/router";
import _ from 'lodash'
import { faCommentDots, faEye, faEyeSlash, faFileUpload, faRocket, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import AppStore from "./models/AppStore";
import LoginUser from './api/LoginUser';
import LoginForm from './layout/LoginForm';
import modalLookup from './data/modalLookup';
import FeatureStore from './models/FeatureStore';
import FeaturePage from './pages/FeaturePage';
import FetchRoadmapData from './api/FetchRoadmapData';
import TitleBar from './pages/TitleBar'
import PageHeading from './pages/PageHeading';
import PageLayout from './layout/PageLayout';
import Discussion from './pages/Discussion';
import FeatureList from './pages/FeatureList';
import AnnouncementList from './pages/AnnouncementList';
import Feed from './pages/sidebar/Feed';
import SidebarButton from './pages/sidebar/SidebarButton';
import GeneralSidebar from './pages/sidebar/GeneralSidebar';
import FeatureSidebar from './pages/sidebar/FeatureSidebar';
import AnnouncementStore from './models/AnnouncementStore';
import AnnouncementPage from './pages/AnnouncementPage';
import FileUploader from './layout/FileUploader';
import AnnouncementsPage from './pages/AnnouncementsPage';
import FeaturesPage from './pages/FeaturesPage';
import AnnouncementSidebar from './pages/sidebar/AnnouncementSidebar';
// import Editor from './layout/editor/Editor';

console.log(process.env.REACT_APP_TEST);

const homePageData = {
	title: <img css={{width: "300px"}} src={process.env.PUBLIC_URL + '/ccv2_logo.png'} />,
	description: (
		<div>
			<p>Welcome to the Coincraft Version 2 Roadmap! </p>
			
			<p>Here you can stay
			up-to-date with the latest developments with Coincraft Version 2 -
			you can find the latest features and updates listed below. You can
			click them to publicly or privately discuss, and even subscribe to
			the latest updates or suggest your own features on the right hand
			side of the page. The ability to upload images and files is coming
			soon!</p>
		</div>
	)
};

const App = observer(() => {
	return (
		<PageLayout
			sidebar={
				<div>
					<SidebarButton
						icon={faPaperPlane}
						onClick={() => AppStore.setModal("featureForm")}
					>
						Request Feature
					</SidebarButton>
					<Router>
						<GeneralSidebar path="/" />
						<FeatureSidebar path="/feature/:url" />
						<AnnouncementSidebar path="/update/:url" />
						<GeneralSidebar path="/updates" />
					</Router>
					{/* <Feed events={pageData.feed} /> */}
				</div>
			}
			body={
				<div>
					{AppStore.needsLogin && <LoginForm />}
					{AppStore.modal &&
						modalLookup[AppStore.modal](AppStore.modalData)}
					<Router>
						<HomePage path="/" />
						<FeaturesPage path="/features" />
						<AnnouncementsPage path="/updates" />
						<FeaturePage path="/feature/:url" />
						<AnnouncementPage path="/update/:url" />
					</Router>
				</div>
			}
		/>
	);
})

export default App;

const HomePage = observer(() => {
	return (
		<div>
			<PageHeading
				title={homePageData.title}
				description={homePageData.description}
			/>
			<LatestAnnouncement
				announcements={AnnouncementStore.announcements}
			/>
			<LatestFeatures features={FeatureStore.features} />
		</div>
	);
})


const LatestAnnouncement = observer(({announcements}) => {
	return (
		<div>
			<TitleBar title="Latest Updates" link="/updates" linkLabel="view all updates..." />
			<AnnouncementList announcements={announcements} />
		</div>
	);
})

const LatestFeatures = observer(({ features }) => {
	return (
		<div>
			<TitleBar
				title="Latest Features"
				link="/features"
				linkLabel="view all features..."
			/>
			<FeatureList features={features} />
		</div>
	);
});


