import { observable, computed, action } from "mobx";
import _ from "lodash";
import uuid from "uuid/v4";
import UserStore from "./UserStore";
import ReactionStore from "./ReactionStore";
import CommentStore from "./CommentStore";
import UpdateStore from "./AnnouncementStore";
import AppStore from "./AppStore";
import { serialize } from "uri-js";

class Feature {
	@observable id = null;
	@observable uuid = uuid();
	@observable urlId = uuid();
	@observable createdAt = Date.now() / 1000;
	@observable updatedAt = Date.now() / 1000;
	@observable deletedAt = null;

	@observable title = "";
	@observable description = {};
	@observable status = "proposed";
	@observable createdById = AppStore.currentUserId;
	@observable requestingFeeback = false;

	constructor(props) {
		this.updateProps(props);
	}

	@action.bound updateProps(props) {
		_.forIn(props, (v, k) => {
			this[k] = v;
		});
	}

	@computed get serialize() {
		return {
			id: this.id,
			uuid: this.uuid,
			urlId: this.urlId,
			deletedAt: this.deletedAt,
			title: this.title,
			description: this.description,
			status: this.status,
			createdById: this.createdById,
			requestingFeeback: this.requestingFeeback
		};
	}
	@computed get serialise() {
		return this.serialize;
	}

	@computed get createdBy() {
		return UserStore.usersById[this.createdById];
	}

	@computed get url() {
		return _.kebabCase(this.title);
	}

	@computed get comments() {
		return CommentStore.commentsByCommentingOnUuid[this.uuid] || [];
	}

	@computed get reactions() {
		return ReactionStore.reactionsByReactingToUuid[this.uuid] || [];
	}

	@computed get announcements() {
		return UpdateStore.announcementsByFeatureUuid[this.uuid] || [];
	}

	@computed get reactionsByValue() {
		const reactionsByValue = {
			"-2": 0,
			"-1": 0,
			"0": 0,
			"1": 0,
			"2": 0
		};
		this.reactions.forEach(r => {
			reactionsByValue[r.value] += 1;
		});
		return reactionsByValue;
	}

	@computed get medianReactionValue() {
		return (
			Object.keys(this.reactionsByValue).reduce((a, b) =>
				this.reactionsByValue[a] > this.reactionsByValue[b] ? a : b
			) || 0
		);
	}

	@computed get averageReactionValue() {
		return _.mean(this.reactions.map(r => r.value)) || 0;
	}

	@computed get totalReactionValue() {
		return _.sum(this.reactions.map(r => r.value)) || 0;
	}

	@computed get currentUserReaction() {
		return AppStore.currentUser.reactionsByReactingToUuid[this.uuid];
	}

	@computed get currentUserSubscription() {
		console.log(
			"TCL: Feature -> @computedgetcurrentUserSubscription -> AppStore.currentUser.subscriptionsByTag",
			AppStore.currentUser.subscriptionsByTag
		);
		return AppStore.currentUser.subscriptionsByTag[this.uuid];
	}
	@computed get statusVal() {
		return statusValLookup[this.status]
	}
}

export default Feature;

const statusValLookup = {
	proposed: "0",
	considering: "1",
	planned: "2",
	inProgress: "3",
	completed: "4"
};
