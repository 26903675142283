import React, { Component } from "react";
/** @jsx jsx */ import { jsx } from "@emotion/core";
import { observer } from "mobx-react";
import _ from "lodash";
import FetchRoadmapData from "../api/FetchRoadmapData";
import AppStore from "../models/AppStore";
import LoginForm from "./LoginForm";

const PageLayout = observer(({body, sidebar}) => {
	if (!AppStore.currentUserId || FetchRoadmapData.status !== "done") {
		return (
			<div>
				{AppStore.needsLogin && <LoginForm />}
				<div>Loading...</div>
			</div>
		);
	};
	return (
		<div
			css={{
				position: "absolute",
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
				display: "flex",
				justifyContent: "space-evenly",
				padding: "2em"
			}}
		>
			<div
				css={{
					display: "inline-flex",
                    height: "calc(100% - 4em)",
                    width: "100%",
					maxWidth: "60em"
				}}
			>
				<div
					className="bodypanel"
					css={{
						height: "100%",
						overflowY: "auto",
						flex: "1 1 auto",
						minWidth: "30em",
						padding: "2em",
						borderRight: "1px solid #ccc"
					}}
				>
					{body}
				</div>
				<div
					css={{
						height: "100%",
						overflowY: "auto",
						flex: "0 0 auto",
						width: "25%",
						minWidth: "8em",
						padding: "2em"
					}}
				>
					{sidebar}
				</div>
			</div>
		</div>
	);
});

export default PageLayout;
